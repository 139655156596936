import React from "react"

import * as S from "./styled"

const LLForm = ({
  action,
  ID,
  maquinaID,
  formID,
  listID,
  placeholderName,
  placeholderEmail,
  msgAceito,
  imgSource,
  btnText,
}) => {
  return (
    <S.LLForm action={action} method="post">
      <S.LLInputHidden id="id" name="id" type="hidden" value={ID} />
      <S.LLInputHidden id="mid" name="mid" type="hidden" value={maquinaID} />
      <S.LLInputHidden id="pid" name="pid" type="hidden" value={formID} />
      <S.LLInputHidden
        id="list_id"
        name="list_id"
        type="hidden"
        value={listID}
      />
      <S.LLInputHidden
        id="provider"
        name="provider"
        type="hidden"
        value="leadlovers"
      />
      <S.LLLabel htmlFor="name">Nome:</S.LLLabel>
      <S.LLInputText
        id="name"
        name="name"
        type="text"
        placeholder={placeholderName}
        required
      />
      <S.LLLabel htmlFor="email">Email:</S.LLLabel>
      <S.LLInputEmail
        id="email"
        name="email"
        type="email"
        placeholder={placeholderEmail}
        required
      />
      <S.LLPrivacyMsg>
        <S.LLInputCheckbox
          id="LGPDValidation"
          name="LGPDValidation"
          type="checkbox"
          value="1"
          required
        />
        {msgAceito}
      </S.LLPrivacyMsg>
      <S.LLLink
        to="/privacidade"
        cover
        direction="left"
        bg="#08070e"
        duration={0.6}
      >
        Políticas de Privacidade
      </S.LLLink>
      <S.LLButton type="submit">{btnText}</S.LLButton>
      <S.LLInputHidden type="hidden" id="source" name="source" value="" />
      <S.LLImage src={imgSource} />
    </S.LLForm>
  )
}

export default LLForm
