import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import LayoutPagesCapture from "../../../../components/LayoutPagesCapture"
import SEO from "../../../../components/seo"
import LLForm from "../../../../components/LLForm"

import * as S from "../../../../components/PagesCapture/styled"

const EbookPalavrasChavesNegativasPage = () => {
  const { ebook_palavras_chaves_negativas } = useStaticQuery(graphql`
    query {
      ebook_palavras_chaves_negativas: file(
        relativePath: { eq: "ebooks/mokup-ebook-palavras-chaves-negativas.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <LayoutPagesCapture>
      <SEO
        title="Ebook - Palavras Chaves Negativas"
        description="Ebook contendo as principais palavras chaves negativas para você utilizar em suas campanhas de Google Ads."
      />
      <S.PagesCaptureDescriptionBlock>
        <S.PagesCaptureDescriptionTitle>
          Baixe Agora o Ebook que vai resolver seu problema de palavras chaves
          negativas e evitar que você tenha prejuízos desnecessários em suas
          campanhas.
        </S.PagesCaptureDescriptionTitle>
        <S.PagesCaptureDescriptionText>
          Nesse Ebook você vai encontrar as principais palavras chaves negativas
          que você deve utilizar em suas campanhas de infoprodutos.
        </S.PagesCaptureDescriptionText>
        <S.PagesCaptureDescriptionText>
          Cheguei nessa lista aprimorando minhas campanhas por vários meses e
          agora estou disponibilizando esse material para te ajudar a melhorar e
          diminuir sua perda de dinheiro em suas campanhas de Google ADS por
          conta de muitas vezes esquecer de negativar algumas palavras.
        </S.PagesCaptureDescriptionText>
        <S.PagesCaptureDescriptionText>
          Não perca tempo, preencha o formulário ao lado e pegue a sua cópia
          agora mesmo.
        </S.PagesCaptureDescriptionText>
      </S.PagesCaptureDescriptionBlock>
      <S.PagesCaptureFormBlock>
        <S.PagesCaptureFormBlockTitle>
          Baixe Agora seu Ebook Grátis!
        </S.PagesCaptureFormBlockTitle>
        <S.PagesCaptureEbookImg
          fluid={ebook_palavras_chaves_negativas.childImageSharp.fluid}
          alt="Capa do Ebook Palavras Chaves Negativas para suas Campanhas do Google Ads"
        />
        <LLForm
          action="https://paginas.rocks/pages/index/530689"
          ID="530689"
          maquinaID="530689"
          formID="18400658"
          listID="530689"
          placeholderName="Informe seu Nome:"
          placeholderEmail="Informe seu email"
          msgAceito="De acordo com as Leis 12.965/2014 e 13.709/2018, que regulam o uso da Internet e o tratamento de dados pessoais no Brasil, autorizo Willian D. Santos a enviar notificações por e-mail ou outros meios e concordo com sua Política de Privacidade*."
          imgSource="https://llimages.com/redirect/redirect.aspx?A=V&p=18400658&m=530689"
          btnText="Download Agora!"
        />
      </S.PagesCaptureFormBlock>
    </LayoutPagesCapture>
  )
}

export default EbookPalavrasChavesNegativasPage
