import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const LLForm = styled.form`
  width: 100%;
`

export const LLInputHidden = styled.input``

export const LLInputText = styled.input`
  width: 100%;
  height: 50px;
  border: 0;
  margin: 0.5rem 0 1.5rem;
  padding: 0.5rem;

  &:focus {
    outline: var(--orange) auto 1px;
  }
`

export const LLInputEmail = styled.input`
  width: 100%;
  height: 50px;
  border: 0;
  margin: 0.5rem 0 1.5rem;
  padding: 0.5rem;

  &:focus {
    outline: var(--orange) auto 1px;
  }
`

export const LLInputCheckbox = styled.input`
  margin: 0 0.5rem 0 0;
  line-height: 16;
  width: 20px;
  height: 20px;
`

export const LLPrivacyMsg = styled.p`
  color: var(--white);
  display: inline-block;
`

export const LLLink = styled(AniLink)`
  width: 100%;
  display: block;
  text-align: right;
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0;
  cursor: pointer;
  border-radius: 0.25rem;
  color: var(--white);
  text-decoration: none;
  font-size: 0.8rem;
  transition: text-decoration 0.5s;

  &:hover {
    text-decoration: underline;
    color: var(--white);
  }
`

export const LLLabel = styled.label`
  color: var(--white);
  text-transform: uppercase;
`

export const LLButton = styled.button`
  cursor: pointer;
  display: block;
  width: 100%;
  border: none;
  text-transform: uppercase;
  text-align: center;
  padding: 1.5rem;
  color: var(--white);
  font-size: 1.2rem;
  background-color: var(--red);

  &:hover {
    text-decoration: underline;
  }
`

export const LLImage = styled.img`
  display: none;
`
